module.exports = [{
      plugin: require('/tmp/ab4e0fb/node_modules/gatsby-plugin-emotion/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/tmp/ab4e0fb/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-134770666-1","head":true},
    },{
      plugin: require('/tmp/ab4e0fb/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
